<template>
  <div class="prompt-multifactor">
    <h5>Would you like to setup Multi-factor authentication now?</h5>
    <div class="field">
      <input
        multi-factor-yes
        type="button"
        @click.prevent="processFirstLogin(true, 'yes')"
        class="button is-primary is-rounded"
        value="Yes"
      /><span class="p-2"></span>
      <input
        multi-factor-no
        type="button"
        @click.prevent="processFirstLogin(true, 'no')"
        class="button is-primary is-rounded"
        value="No"
      /><span class="p-2"></span>
      <input
        multi-factor-later
        type="button"
        @click.prevent="processFirstLogin(false, 'later')"
        class="button is-primary is-rounded"
        value="Ask Me Later"
      />
    </div>
  </div>
</template>

<script type="es6">
import { twoFactorAuthentication } from '@/services/TwoFactorAuthentication/store'
import { notifyError } from '@/services/notify'
import { mapGetters } from 'vuex';
import { appId } from '@/config'

export default {
  data: () => ({
    isDebug: true,
    firstLogin: false,
    scope: ''
  }),

  watch: {

  },

  computed: {
    ...mapGetters('user', ['appId'])
  },

  mounted() {
    this.scope = appId()
  },

  methods: {
  async processFirstLogin(firstLogin, confirmed) {
      try {
        await twoFactorAuthentication.dispatch('saveFirstLoginMultiFactor', {
        firstLoginMultiFactor: firstLogin || false,
        done: async() => {
            if(confirmed === 'yes')
            {
              let redirectPath = ''

              if(this.scope === 'homeowner')
              {
                redirectPath ='/userSettings/ho-twofactorauthentication?fromFirstLogin=true#tab'
              }
              else if(this.scope === 'vendor')
              {
                redirectPath ='/userSettings/vendor-twofactorauthentication?fromFirstLogin=true#tab'
              }
              else if(this.scope === 'management')
              {
                redirectPath ='/userSettings/twofactorauthentication?fromFirstLogin=true#tab'
              }

              //NOTE: This version of vue has a navigation guard that needs overridden with a catch after the route to avoid the guard error
              this.$router.push({
                path: redirectPath
              }).catch(() => {});
            }
            else
            {
              //redirect to dashboard
              this.$router.push({
                path: '/?nocheck=true'
              })

              //Necessary to force a default unit selection after a "nocheck" router push
              setTimeout(() => {
                window.location.reload()
              }, 200)
            }
        }
        })
      } catch(e)
      {
        notifyError(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-box {
  max-width: 480px;
}

.prompt-multifactor {
  display: flex;
  height: 100%;
  min-height: 75vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
